import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { GREEN, RED_500, WHITE } from 'assets/theme/colors';
import FlightCryptoTransfer from 'modules/mobile/flight/payment/orderDetail/FlightCryptoTransfer';
import moment from 'moment';
import { IconClock } from 'public/icons';
import { useTranslation } from 'react-i18next';
import { PINK_MT } from 'setupTheme';
import { C_DATE_TIME_FORMAT } from 'utils/moment';
import { getMultilingualTime, isEmpty } from 'utils/helpers';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    width: '100%',
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: 24,
    background: WHITE,
    borderRadius: '20px 20px 0 0',
    borderTop: 'solid 4px',
    borderTopColor: GREEN,
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    marginTop: '10px',
    color: theme.palette.black.black3,
  },
  bankTransferWarning: {
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: PINK_MT,
    padding: '0 12px',
    marginTop: 16,
  },
  timeBlock: {
    width: 'fit-content',
    borderRadius: 4,
    background: '#FEEBC8',
    padding: '4px 8px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: '#ED8936',
  },
}));

const BookingCryptoTransfer = ({ paymentStatusDetail = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentTime = moment().unix();
  const [time, setTime] = useState(1);
  const [open, setOpen] = useState(false);
  let hours = Math.floor(time / 3600); // get hours
  let minutes = Math.floor((time - hours * 3600) / 60); // get minutes
  let seconds = time - hours * 3600 - minutes * 60; //  get seconds
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  const expiredTime = moment(
    paymentStatusDetail?.cryptoTransferInfo?.expiredTime,
    C_DATE_TIME_FORMAT
  );
  const locale = localStorage.getItem('i18nextLng') || 'vi';

  useEffect(() => {
    if (time > 0) {
      setOpen(false);
      const timer = setTimeout(() => setTime(time - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setOpen(true);
    } // eslint-disable-next-line
  }, [time]);
  useEffect(() => {
    if (!isEmpty(paymentStatusDetail)) {
      const date = new Date(
        paymentStatusDetail?.cryptoTransferInfo?.expiredTime
      );
      const timestamp = date.getTime();
      const diffTime = timestamp / 1000 - currentTime;
      setTime(diffTime);
    }
  }, [paymentStatusDetail, currentTime]);

  return (
    <Box className={classes.wrapContainer}>
      <Box className={classes.container}>
        <Box className={classes.boxStatus}>
          <CircularProgress size={64} color="primary" />
          <Box className={classes.mainStatus}>
            {t('IDS_MT_TEXT_STATUS_PAYMENT_WAITTING')}
          </Box>

          <Box className={classes.timeBlock}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <IconClock className="svgFillAll" style={{ stroke: '#ED8936' }} />
              <span style={{ fontSize: 14, lineHeight: '17px' }}>
                {open
                  ? t('IDS_MT_EXPRITE_TIME_PAYMENT')
                  : `${t(
                      'IDS_MT_TEXT_PAYMENT_COMPLETION_TIME'
                    )}: ${minutes}:${seconds}`}
              </span>
            </Box>
          </Box>

          <Box className={classes.desc}>
            {t('IDS_TEXT_FLIGHT_BOOKING_BANK_TRANSFER_2')}&nbsp;
            <span style={{ color: RED_500, fontWeight: '600' }}>
              {getMultilingualTime({ locale, expiredTime })}
            </span>
          </Box>
        </Box>
        {moment().isBefore(expiredTime) && (
          <>
            <FlightCryptoTransfer paymentStatusDetail={paymentStatusDetail} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default BookingCryptoTransfer;
